// extracted by mini-css-extract-plugin
export var alignLeft = "t_qf d_bG";
export var alignCenter = "t_bP d_bD";
export var alignRight = "t_qg d_bH";
export var textAlignLeft = "t_q3";
export var textAlignCenter = "t_q4";
export var textAlignRight = "t_q5";
export var hoursInnerWrapperAlt = "t_rR d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "t_rS d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "t_rT d_dw";
export var titleMargin = "t_q8 d_cw";
export var hoursInnerInnerWrapper = "t_rV d_cz";