// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "w_r6 d_bD";
export var storyRowWrapper = "w_hx d_hx d_bK";
export var storyLeftWrapper = "w_r7 d_bz d_bP";
export var storyWrapperFull = "w_r8 d_cD";
export var storyWrapperFullLeft = "w_pj d_cD d_bz d_bP d_bD";
export var contentWrapper = "w_mS d_hy";
export var storyLeftWrapperCenter = "w_r9 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "w_sb d_hF";
export var storyHeader = "w_sc d_hD d_w d_cs";
export var storyHeaderCenter = "w_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "w_hB d_hB d_by d_dw";
export var storyBtnWrapper = "w_sd d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "w_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "w_sf d_fg d_Z";
export var imageWrapperFull = "w_sg d_w d_H d_bf d_Z";
export var SubtitleSmall = "w_qb z_qb z_sm z_sy";
export var SubtitleNormal = "w_qc z_qc z_sm z_sz";
export var SubtitleLarge = "w_qd z_qd z_sm z_sB";