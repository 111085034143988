// extracted by mini-css-extract-plugin
export var alignLeft = "q_qf d_bG";
export var alignCenter = "q_bP d_bD";
export var alignRight = "q_qg d_bH";
export var textAlignLeft = "q_q3";
export var textAlignCenter = "q_q4";
export var textAlignRight = "q_q5";
export var embedInnerWrapperDesign1 = "q_q6 d_bM";
export var embed = "q_q7 d_b1";
export var titleMargin = "q_q8 d_cw d_dw";
export var subtitleMargin = "q_q9 d_cs d_dw";
export var paragraphMargin = "q_rb d_cw d_dw";
export var SubtitleSmall = "q_qb z_qb z_sm z_sy";
export var SubtitleNormal = "q_qc z_qc z_sm z_sz";
export var SubtitleLarge = "q_qd z_qd z_sm z_sB";
export var BodySmall = "q_rc z_rc z_sm z_sG";
export var BodyNormal = "q_rd z_rd z_sm z_sH";
export var BodyLarge = "q_rf z_rf z_sm z_sJ";